import { addMinutes } from "date-fns/addMinutes";
import { differenceInMinutes } from "date-fns/differenceInMinutes";
import { parseISO } from "date-fns/parseISO";
import PropTypes from "prop-types";
import { memo, useCallback, useEffect, useMemo, useState } from "react";

import getTimeAgo from "utils/date/getTimeAgo";
import getTimeAgoUTC from "utils/date/getTimeAgoUTC";

function DateAgo(props) {
  const { date, UTC } = props;
  const parsedDate = useMemo(() => parseISO(date), [date]);

  const updateEvery = useMemo(() => {
    const offset = new Date().getTimezoneOffset();
    const minutesAgo = differenceInMinutes(
      UTC ? addMinutes(Date.now(), offset) : Date.now(),
      parsedDate
    );
    if (minutesAgo < 120) {
      // update every minute if it's less than 2 hours
      return 60;
    }
    if (minutesAgo < 2 * 24 * 60) {
      // update every hour if it's less than 2 days
      return 60 * 60;
    }
    return null;
  }, [UTC, parsedDate]);

  const [, forceUpdate] = useState(1);

  const doUpdate = useCallback(() => {
    forceUpdate((prev) => prev + 1);
  }, []);

  useEffect(() => {
    let intervalHandle;
    if (updateEvery > 0) {
      intervalHandle = setInterval(doUpdate, updateEvery * 1000);
    }

    return () => {
      if (intervalHandle) {
        clearInterval(intervalHandle);
      }
    };
  }, [doUpdate, updateEvery]);

  const agoFn = UTC ? getTimeAgoUTC : getTimeAgo;

  return <span>{agoFn(parsedDate)}</span>;
}

DateAgo.propTypes = {
  updateEvery: PropTypes.number,
  date: PropTypes.string.isRequired,
  style: PropTypes.object,
  UTC: PropTypes.bool,
};

DateAgo.defaultProps = { updateEvery: 5, style: {}, UTC: true };

export default memo(DateAgo);
