import { format } from "date-fns/format";
import { formatDistanceToNow } from "date-fns/formatDistanceToNow";
import { isAfter } from "date-fns/isAfter";
import { parseISO } from "date-fns/parseISO";
import { subMonths } from "date-fns/subMonths";

import { SHORT_DATE_ONLY_FORMAT } from "constants/date";

export default function getTimeAgo(dateString, formatters = {}) {
  const date = parseISO(dateString);

  if (isAfter(date, subMonths(new Date(), 1))) {
    const beforeDate = formatDistanceToNow(date, { addSuffix: true });

    return formatters.before ? formatters.before(beforeDate) : beforeDate;
  }

  const afterDate = format(date, SHORT_DATE_ONLY_FORMAT);

  return formatters.after ? formatters.after(afterDate) : afterDate;
}
