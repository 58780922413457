import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { saveProNotePromise } from "routines/podcast";
import { useDebouncedCallback } from "use-debounce";

import { fieldStyles } from "components/Auth/AuthForms/Layout/loginStyles";
import DateAgo from "components/Common/DateAgo";
import PageSidebarPanel from "components/Entities/Page/PageSidebarPanel";

import PodchaserProBadge from "../../../components/Branding/PodchaserProBadge";

import getPodcastName from "utils/entity/getPodcastName";
import generateTransition from "utils/generateTransition";
import sendGAEvent from "utils/sendGAEvent";

import useHasProForPodcast from "hooks/useHasProForPodcast";
import useRoutinePromises from "hooks/useRoutinePromises";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  textarea: {
    ...fieldStyles.inputField,
    padding: "12px 15px 11px",
    fontSize: "0.8125rem",
    minHeight: 80,
    transition: generateTransition({ target: "minHeight" }),
    [ScreenSizes.mdAndBelow]: {
      ...fieldStyles.inputField[ScreenSizes.mdAndBelow],
      width: "100%",
      maxWidth: 500,
    },
    ":focus": {
      minHeight: 240,
    },
  },
  proConciergeProTag: {
    position: "absolute",
    top: ".375rem",
    right: ".375rem",
  },
  expandIcon: {
    marginLeft: "auto",
  },
  footerText: {
    fontSize: "0.75rem",
    marginTop: "0.5rem",
    color: "#555",
    display: "flex",
    justifyContent: "space-between",
  },
  error: {
    ...gStyles.fontSemiBold,
    fontSize: ".75rem",
    color: colours.negative,
    marginTop: ".5rem",
  },
  title: {
    ...gStyles.avalonBold,
    marginTop: "0.4rem",
    padding: "0 0 1rem",

    [ScreenSizes.smAndAbove]: {
      fontSize: "1em",
    },
    [ScreenSizes.lgAndAbove]: {
      fontSize: "1em",
      marginTop: 0,
    },
  },
};

const ProNotes = (props) => {
  const { podcast, noPanel, index } = props;
  const { styles } = useStyles(baseStyles, props);

  const hasProPodcastPermission = useHasProForPodcast(podcast.get("id"));
  // edited is just used to determine when the note has changed on the page so we won't overwrite it with content from the server
  // once set to true, there's no need to set it back to false as we should be in sync by then
  const [edited, setEdited] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [note, setNote] = useState(
    podcast.getIn(["user_data", "note", "content"])
  );
  const lastUpdated = podcast.getIn(["user_data", "note", "updated_at"]);

  useEffect(() => {
    if (!edited) {
      setNote(podcast.getIn(["user_data", "note", "content"]));
    }
  }, [edited, podcast]);

  const { saveProNote } = useRoutinePromises({
    saveProNote: saveProNotePromise,
  });

  const handleNoteSave = useCallback(
    (content) => {
      setSaving(true);
      sendGAEvent({
        action: "proNoteSaved",
        podcast_id: podcast && podcast.get("id"),
        podcast_name: podcast && getPodcastName(podcast),
        content,
        page: "Podcast View - Sidebar Notes",
      });
      saveProNote({ podcast_id: podcast.get("id"), content })
        .then(() => {
          setError(null);
        })
        .catch((e) => {
          setError(e.message);
        })
        .finally(() => {
          setSaving(false);
        });
    },
    [podcast, saveProNote]
  );

  const debouncedSaveProNote = useDebouncedCallback(handleNoteSave, 500, {
    leading: false,
  });

  // this will flush any final debounced events in the case that this component unmounts before they're sent
  useEffect(() => () => debouncedSaveProNote.flush(), [debouncedSaveProNote]);

  const handleNoteChange = useCallback(
    (e) => {
      setEdited(true);
      setNote(e.target.value);
      debouncedSaveProNote.callback(e.target.value);
    },
    [debouncedSaveProNote]
  );

  if (hasProPodcastPermission) {
    return (
      <PageSidebarPanel noPanel={noPanel} renderOnMount>
        <label htmlFor="myNotes" className={css(styles.title)}>
          My Notes
        </label>
        <div className={css(styles.proConciergeProTag)}>
          <PodchaserProBadge />
        </div>

        <textarea
          id="myNotes"
          className={css(styles.textarea)}
          rows={4}
          onChange={handleNoteChange}
          value={note}
        />

        {error || saving ? (
          <span
            className={css(
              styles.footerText,
              error && styles.error,
              saving && styles.saving
            )}
          >
            <span>{error || (saving && "Saving changes...")}</span>
          </span>
        ) : (
          <span className={css(styles.footerText)}>
            {lastUpdated && (
              <span>
                Last Updated: <DateAgo date={lastUpdated} /> ago
              </span>
            )}
          </span>
        )}
      </PageSidebarPanel>
    );
  }

  return null;
};

ProNotes.propTypes = {
  podcast: PropTypes.instanceOf(Map).isRequired,
  noPanel: PropTypes.bool,
};

ProNotes.defaultProps = {
  noPanel: false,
};

export default ProNotes;
