import { addMinutes } from "date-fns/addMinutes";
import { formatDistanceToNow } from "date-fns/formatDistanceToNow";
import { parseISO } from "date-fns/parseISO";

export default function getTimeAgoUTC(date) {
  const offset = new Date().getTimezoneOffset();
  const isoDateString = new Date(date).toISOString();

  return formatDistanceToNow(addMinutes(parseISO(isoDateString), -offset));
}
